import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class UploadBlacklistFile extends Component {
	constructor(props) {
		super(props);
		this.blistFileInput = React.createRef();
		let sitePath;
		let filePath;

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			blistFileInput: this.blistFileInput,
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			blistUploaded: false
		}

	}

	componentDidMount() {
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setUploadType = (type, uploadTypeKey) => {
		this.setState({
			[uploadTypeKey]: type,
		});
	}

	setMessageShow = (status) => {
		this.setState({
		});
	}

	onFileChangeHandler = event => {
		//if ( this.checkMimeType( event ) && this.checkFileSize( event ) ) {
		if (this.checkMimeType(event)) {

		}
	}
	// Add Voice File

	handleAddBlistFileSubmit = (event) => {
		event.preventDefault();
		const formData = cloneDeep(this.state);
		console.log(formData);

		const blistFile = formData.blistFileInput.current.files[0];
		const fd = new FormData();
		fd.append('blistFile', blistFile);
		fd.append('userId', this.state.userId);

		return Fetcher.post(formData.apiPath + '/api/obd/blistupload', { method: 'POST', body: fd })
			.then(res => {
				let blistUploaded = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					Notify.notify(res.message);
					blistUploaded = true;
				}
				this.setState({
					blistUploaded: blistUploaded
				})
			})
			.catch(err => {
				console.log('Error in uploading Blacklist File to Server', err);
			});
	}

	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}

	getFileNameFromUrl = (url) => {
		var n = url.lastIndexOf('/');
		return url.substring(n + 1);
	}

	checkMimeType = (event) => {
		let files = event.target.files
		let allowedTypes = ['text/plain', 'text/csv'];
		if (event.target.name === 'newContactsFile') {
			allowedTypes = ['text/plain', 'text/csv'];
		}
		let err = [] // create empty array

		for (var x = 0; x < files.length; x++) {
			if (allowedTypes.every(type => files[x].type !== type)) {
				err[x] = files[x].type + ' is not a supported format\n';
				// assign message to array
			}
		};
		for (var z = 0; z < err.length; z++) { // loop create toast massage
			event.target.value = null;
		}
		return true;
	}

	checkFileSize = (event) => {
		let allowedSize = 1;
		if (event.target.name === 'newContactsFile') {
			allowedSize = 20;
		}

		let files = event.target.files
		let err = [];
		for (var x = 0; x < files.length; x++) {
			if (((files[x].size / 1024 / 1024).toFixed(2)) > allowedSize) {
				err[x] = files[x].size + ' is too large, please pick a smaller file\n';
			}
		};
		for (var z = 0; z < err.length; z++) {
			event.target.value = null;
		}
		return true;
	}

	render() {

		let submitButtonlabel = 'Upload File';
		if (this.state.blistUploaded === true) {
			return <Redirect to={'/voice-blacklist/'} />
		}
		return (

			<Form className="add-campaign-form" onSubmit={(e) => this.handleAddBlistFileSubmit(e)}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Blacklist Bulk Upload</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Label>Upload Blacklist File(.txt OR .csv format only)</Form.Label>
										<Form.Group controlId="cli-file-upload">
											<Form.Control required name="newblistFile" onChange={this.onFileChangeHandler} ref={this.blistFileInput} type="file" />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">{submitButtonlabel}</Button>
							</Card.Body>
						</Card>

					</Col>
				</Row>
			</Form>
		);
	}
}

export default UploadBlacklistFile;