import React, { Component } from 'react';
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../../Helpers/notification.js";

import "../index.css";
class CreateUser extends Component {
	constructor(props) {
		super(props);
		this.logoInput = React.createRef();
		this.state = {
			username: '',
			password: '',
			planId: '',
			confirmPassword: '',
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			credits: '',
			domain: '',
			channels: 0,
			parentChannels: 0,
			userChannels: 0,
			logoInput: this.logoInput,
			logo: '',
			redirectFlag: false,
			userType: '',
			planList: [],
			accountType: '',
			parentAccount: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			channelsAvailable: '',
			sendDate: new Date(),
			logoDomain: false,
			moduleList: [],
			parentModuleList: [],
			moduleId: '',
			margin: '',
			interval: '',
			threshold: '',
			tier : '',
			setting : false,
			isSub : false,
			showSubmission: false,
			pulseDuration: 0,
			Setting1: false,
			Setting2: false,
			Setting3: false,
			Setting4: false,
			margin1: '',
			interval1: '',
			threshold1: '',
			starting_range1: '',
			ending_range1: '',
			margin2: '',
			interval2: '',
			threshold2: '',
			starting_range2: '',
			ending_range2: '',
			margin3: '',
			interval3: '',
			threshold3: '',
			starting_range3: '',
			ending_range3: '',
			margin4: '',
			interval4: '',
			threshold4: '',
			starting_range4: '',
			ending_range4: '',
			loading: false,
		};

	}
	componentDidMount() {
		const apiPath = this.state.apiPath;
		const userId = this.state.userId;
		const parent = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					parentChannels: res.channels,
					parentAccount: res.accountType,
					parentModuleList: res.modules,
					pulseDuration: res.pulseDuration,
					showSubmission: res.sub === 1 ? false : true,
					isSub : res.sub,
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		Fetcher.get(apiPath + '/api/obd/user/channels/' + userId)
			.then(res => {
				this.setState({
					userChannels: res.userChannels
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + userId)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
		
		// Get Module List
		Fetcher.get(apiPath + '/api/obd/module')
			.then(res => {
				this.setState({
					moduleList: res
				})
			})
			.catch(err => { console.log('Error in fetching Modules', err) });

		if(this.state.pulseDuration > 1){
			this.state.setting = false
		}
	}

	sendLaterDateChange = date => {
		this.setState({
			sendDate: date
		});
	};
	
	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	handleSelect = function (selectedItems) {
		const servicesSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			servicesSelected.push(selectedItems[i].value);
		}
		this.setState({
			moduleId: servicesSelected.toString()
		});
	}
	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}
	toggleCheckedChange = (e, name) => {
		this.setState({
			[name]: e.target.checked,
		});
	};
	hideModal = () => {
		this.setState({
			showModal: false,
			redirectFlag: this.state.redirectFlag
		});
	}

	registerUser = (event) => {
		event.preventDefault();
		this.setState({ loading: true });
		const state = cloneDeep(this.state);
		if(state.moduleId === '')
		{
			state.moduleId = null;
		}
		if (state.confirmPassword !== state.password) {
			alert('Password & Confirm Password don\'t match');
			this.setState({ loading: false });
			return;
		}
		if (state.password.length < 8) {
			alert('Password cannot be less than 8 characters');
			this.setState({ loading: false });
			return;
		}
		if(state.channels > state.parentChannels - state.userChannels)
		{
			alert('Channel Limit Exceeded');
			this.setState({ loading: false });
			return;
		}
		// if(state.pulseDuration > 1 && state.setting === true){
		// 	alert('User Pulse Duration is Greater than 1 while applying setting.');
		// 	return;
		// }
		const date = state.sendDate;
		let sendDateTime = this.addZero(date.getFullYear());
		sendDateTime += "-";
		sendDateTime += this.addZero((date.getMonth() + 1));
		sendDateTime += "-"
		sendDateTime += this.addZero(date.getDate());
		sendDateTime += " 23:59:59"

		
		var logo = null;
		var fd = new FormData();

		const settings = {};

		for (let i = 1; i <= 4; i++) {
			if (i === 1 ){
            	const planList = this.state.planList;
		    	const matchingPlan = planList.find(plan => plan.planId == this.state.planId);
				if (matchingPlan && matchingPlan.pulseDuration != 1){
					this.setState({ Setting1: false });
					continue;
				}
			}
			const setting = state[`Setting${i}`];
			if (setting) {
				settings[`tier${i}`] = {
					margin: state[`margin${i}`],
					interval: state[`interval${i}`] || "",
					threshold: state[`threshold${i}`],
					startRange: state[`interval${i}`] ? "" : state[`starting_range${i}`],
					endRange: state[`interval${i}`] ? "" : state[`ending_range${i}`]
				};
				if (parseInt(state[`starting_range${i}`]) >= parseInt(state[`ending_range${i}`])) {
					alert(`For Setting${i}, starting range cannot be greater than or equal to ending range`);
					this.setState({ loading: false });
					return;
				}
				// if (i === 1 && state.pulseDuration > 1) {
				// 	alert('User Pulse Duration is Greater than 1 while applying setting for Setting1');
				// 	return;
				// }
			}
		}

		let isSubValue = 0;
		if(this.state.isSub){
			isSubValue = 1;
		}

		if(this.state.logoDomain)
		{
			if(Object.keys(settings).length !== 0){
				logo = state.logoInput.current.files[0];
			    fd.append('username', state.username);
			    fd.append('password', state.password);
			    fd.append('planId', state.planId);
			    fd.append('name', state.name);
			    fd.append('emailid', state.emailid);
			    fd.append('number', state.number);
			    fd.append('address', state.address);
			    fd.append('company', state.company);
			    fd.append('pincode', state.pincode);
			    fd.append('logo', logo);
			    fd.append('domain', state.domain);
			    fd.append('parent', this.props.app.userId);
			    fd.append('accountType', state.accountType);
			    fd.append('userType', state.userType);
			    fd.append('channels', state.channels);
			    fd.append('expiryDate', sendDateTime);
			    fd.append('moduleId',state.moduleId);
				fd.append('isSetting',Boolean(Object.keys(settings).length));
				fd.append('margin',state.margin);
				fd.append('interval',state.interval);
				fd.append('threshold',state.threshold);
				fd.append('tier',state.tier);
				fd.append('settings', JSON.stringify(settings));
				fd.append('sub',isSubValue);
			    this.saveDataWithLogo(fd);
			}
            else{
		
				logo = state.logoInput.current.files[0];
			    fd.append('username', state.username);
			    fd.append('password', state.password);
			    fd.append('planId', state.planId);
			    fd.append('name', state.name);
			    fd.append('emailid', state.emailid);
			    fd.append('number', state.number);
			    fd.append('address', state.address);
			    fd.append('company', state.company);
			    fd.append('pincode', state.pincode);
			    fd.append('logo', logo);
			    fd.append('domain', state.domain);
			    fd.append('parent', this.props.app.userId);
			    fd.append('accountType', state.accountType);
			    fd.append('userType', state.userType);
			    fd.append('channels', state.channels);
			    fd.append('expiryDate', sendDateTime);
			    fd.append('moduleId',state.moduleId);
				fd.append('isSetting',Boolean(Object.keys(settings).length));
				fd.append('margin',state.margin);
				fd.append('interval',state.interval);
				fd.append('threshold',state.threshold);
				fd.append('tier',state.tier);
				fd.append('settings', JSON.stringify(settings));
				fd.append('sub',isSubValue);
			    this.saveDataWithLogo(fd);
			}
			// logo = state.logoInput.current.files[0];
			// fd.append('username', state.username);
			// fd.append('password', state.password);
			// fd.append('planId', state.planId);
			// fd.append('name', state.name);
			// fd.append('emailid', state.emailid);
			// fd.append('number', state.number);
			// fd.append('address', state.address);
			// fd.append('company', state.company);
			// fd.append('pincode', state.pincode);
			// fd.append('logo', logo);
			// fd.append('domain', state.domain);
			// fd.append('parent', this.props.app.userId);
			// fd.append('accountType', state.accountType);
			// fd.append('userType', state.userType);
			// fd.append('channels', state.channels);
			// fd.append('expiryDate', sendDateTime);
			// fd.append('moduleId',state.moduleId);
			// this.saveDataWithLogo(fd);
		}
		else
		{
			if(Object.keys(settings).length !== 0){
				
			    const data = {
				          username: state.username,
				          password: state.password,
				          planId: state.planId,
				          name: state.name,
				          emailid: state.emailid,
				          number: state.number,
				          address: state.address,
				          company: state.company,
				          pincode: state.pincode,
				          parent: this.props.app.userId,
				          accountType: state.accountType,
				          userType: state.userType,
				          channels: state.channels,
				          expiryDate: sendDateTime,
				          moduleId: state.moduleId,
				          settings: settings,
						  sub: isSubValue,
			    } 
			this.saveData(data);
		   }
		   else{
			   
			    const data = {
				      username: state.username,
				      password: state.password,
				      planId: state.planId,
				      name: state.name,
				      emailid: state.emailid,
				      number: state.number,
				      address: state.address,
				      company: state.company,
				      pincode: state.pincode,
				      parent: this.props.app.userId,
				      accountType: state.accountType,
				      userType: state.userType,
				      channels: state.channels,
				      expiryDate: sendDateTime,
				      moduleId: state.moduleId,
					  sub: isSubValue,
			    }
			this.saveData(data);
		   }

		}
	}
	saveDataWithLogo = (fd) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register1', { method: 'POST', body: fd })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'User Created Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				} else {
					this.setState({
						loading: false 
					});
				}
			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				Notify.notify('Error in Registering Account');
				this.setState({
					loading: false 
				});
			});

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/register', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				if(res.message === 'User Created Successfully')
				{
					this.setState({
						redirectFlag: true
					})
				} else {
					this.setState({
						loading: false 
					});
				} 
			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				Notify.notify('Error in Registering Account');
				this.setState({
					loading: false 
				});
			});

	}

	setMessageShow = (status) => {
		this.setState({
			showModal: false,
		});
	}

	renderSettingOption = (optionName) => {
		const isAdmin = this.props.app.role === 'ROLE_SADMIN';
		const optionState = this.state[optionName];
		const marginValue = this.state[`margin${optionName.slice(-1)}`];
		const thresholdValue = this.state[`threshold${optionName.slice(-1)}`];
		const isTier2or3 = optionName === 'Setting2' || optionName === 'Setting3';
		const displayName = `Setting${optionName.slice(-1)}`;
		let intervalValue;

        if (!isTier2or3) {
          intervalValue = this.state[`interval${optionName.slice(-1)}`];
        }
	
		return (
			<div>
				<Row>
					{isAdmin && (
						<Col sm={3}>
							<Form.Group className="form-group">
								<Form.Check
									checked={optionState}
									onChange={(e) => this.toggleCheckedChange(e, optionName)}
									custom
									inline
									name={optionName}
									label={`${displayName}`}
									id={optionName}
									type="checkbox"
								/>
							</Form.Group>
						</Col>
					)}
				</Row>
				<Row>
					{optionState && isAdmin && (
						<>
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Select
										className="dd"
										required
										name={`margin${optionName.slice(-1)}`}
										value={marginValue}
										onChange={(e) => this.setvalue(e)}
										as="select"
									>
										<option value="">Select Margin</option>
										{[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((percent, index) => (
											<option key={index} value={index + 1}>{percent}%</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							{isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`starting_range${optionName.slice(-1)}`}
                                            value={this.state[`starting_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"        
                                        >        
                                            <option value="">Starting Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                                <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}        
                                        </Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`ending_range${optionName.slice(-1)}`}
                                            value={this.state[`ending_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"
                                        >
                                            <option value="">Ending Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                              <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}
                                        </Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							{!isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select
												className="dd"
												required
												name={`interval${optionName.slice(-1)}`}
												value={intervalValue}
												onChange={(e) => this.setvalue(e)}
												as="select"
											>
												<option value="">Select Interval</option>
												{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
													<option key={seconds} value={seconds}>{seconds} sec</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Control
										required
										name={`threshold${optionName.slice(-1)}`}
										type="number"
										placeholder="Threshold"
										onChange={(e) => this.setvalue(e)}
										value={thresholdValue}
									/>
								</Form.Group>
							</Col>
						</>
					)}
				</Row>
			</div>
		);
	};
	
	
	
	
	
	render() {
		if (this.state.redirectFlag === true) {
			return <Redirect to={'/user-list/'} />
		}
		const planList = cloneDeep(this.state.planList);
		let matchingPlan;
		if(planList && planList.length > 0){
			matchingPlan = planList.find(plan => plan.planId == this.state.planId);
		} 
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option value="" >No Plan found</option>
		}
		const moduleList = cloneDeep(this.state.moduleList);
		let moduleDropdown = '';
		if (!isEmpty(moduleList) && (!('error' in moduleList))) {
			moduleDropdown = Array.isArray(moduleList) ? moduleList.map((obj, index) => {
				return <option value={obj.moduleId} key={`planid${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			moduleDropdown = <option value="" >No Module found</option>
		}

		const parentModuleList = cloneDeep(this.state.parentModuleList);
		let parentModuleDropdown = '';
		if (!isEmpty(parentModuleList) && (!('error' in parentModuleList))) {
			parentModuleDropdown = Array.isArray(parentModuleList) ? parentModuleList.map((obj, index) => {
				return <option value={obj.moduleId} key={`planid${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			parentModuleDropdown = <option value="" >No Module found</option>
		}

		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
			const sendLater = this.state.sendLater;
			let sendLaterDatepicker = '';
			sendLaterDatepicker = 
			<Col sm={3}>
				<Form.Group className="form-group expiry required control-label">
					<Form.Label>User Expiry</Form.Label>
					<DatePicker
						className="form-control"
						selected={this.state.sendDate}
						minDate={new Date()}
						timeCaption="Time"
						dateFormat="yyyy-MM-dd"
						onChange={this.sendLaterDateChange}
						filterTime={filterTime}
					/>
				</Form.Group>
			</Col>
			
		return (
			<Form method="post" onSubmit={this.registerUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Create New User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} autoComplete="off" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} autoComplete="new-password"/>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue(e)} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="pincode" type="number" placeholder="Pincode" onChange={e => this.setvalue(e)} value={this.state.pincode} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Customer Type</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												<option value="0">Promotional</option>
												<option value="1">Transactional</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="accountType" value={this.state.accountType} onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Account Type</option>
												{(this.state.parentAccount === 0) && <option value="0">Promotional</option>}
												{(this.state.parentAccount === 1) && <option value="1">Transactional</option>}
											</Form.Select>
										</Form.Group>
									</Col>}
									<Col sm={3}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Voice Plan</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group className="form-group channels required control-label">
											{(this.props.app.role === 'ROLE_ADMIN') && <Form.Label>Channels Available - {this.state.parentChannels - this.state.userChannels}</Form.Label>}
											{(this.props.app.role === 'ROLE_SADMIN') && <Form.Label>Channels</Form.Label>}
											<Form.Control required name="channels" type="text" placeholder="No. of Channels" onChange={e => this.setvalue( e )}  />
										</Form.Group>
									</Col>
									{sendLaterDatepicker}
									{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Group className="form-group channels control-label">
											<Form.Label>Select Services(Multiple Services Allowed)</Form.Label>
											<Form.Select className="dd" name="moduleId" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
												{moduleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={4}>
										<Form.Group className="form-group channels control-label">
											<Form.Label>Select Services(Multiple Services Allowed)</Form.Label>
											<Form.Select className="dd" name="moduleId" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select">
												{parentModuleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>}
								</Row>

                                <Row>
									{/* {(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.setting} onChange={( e ) => this.toggleChange( e, 'setting' )} custom inline name="setting" label="Setting" id="setting" type="checkbox" />
										</Form.Group>
									</Col>} */}
									{(this.props.app.role === 'ROLE_SADMIN' || this.state.showSubmission) && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.isSub} onChange={( e ) => this.toggleChange( e, 'isSub' )} custom inline name="isSub" label="Submission" id="isSub" type="checkbox" />
										</Form.Group>
										</Col>}
								</Row>

								<Row>
								  {((matchingPlan && matchingPlan.pulseDuration == 1)|| matchingPlan === undefined ) && this.renderSettingOption('Setting1')}
								  {this.renderSettingOption('Setting2')}
								  {this.renderSettingOption('Setting3')}
								  {this.renderSettingOption('Setting4')}
								</Row>

								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Check checked={this.state.logoDomain} onChange={( e ) => this.toggleChange( e, 'logoDomain' )} custom inline name="logoDomain" label="Send Logo and Domain" id="logoDomian" type="checkbox" />
										</Form.Group>
									</Col>
								</Row>
								{(this.state.logoDomain) && <Row>
									<Col sm={3}>
										<Form.Group >
											<Form.Label>Logo</Form.Label>
											<Form.Control name="logo" onChange={this.onFileChangeHandler} ref={this.logoInput} type="file" />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
										<Form.Label>Domain</Form.Label>
											<Form.Control name="domain" type="text" placeholder="Domain" onChange={e => this.setvalue( e )} value={this.state.domain} />
										</Form.Group>
									</Col>
								</Row>}
								<Button className="btn-round" variant="primary" type="submit" disabled={this.state.loading}>Create User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default CreateUser;