import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../index.css";

// import "./index.css";
class UpdateUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			emailid: '',
			number: '',
			address: '',
			company: '',
			pincode: '',
			planId: '',
			planName: '',
			accountType: '',
			account: '',
			type: '',
			userType: '',
			channels: '',
			redirectToUserManagement: false,
			planList: [],
			sendDate: '',
			expiryDate: '',
			moduleList: '',
			parentModuleList: '',
			moduleRows: [],
			allocatedmoduleList: [],
			moduleId: '',
			isSub : false,
			isSubValue:false,
			showSubmission: false,
			settings: {},
			Setting1: false,
			Setting2: false,
			Setting3: false,
			Setting4: false,
			margin1: '',
			interval1: '',
			threshold1: '',
			starting_range1: '',
			ending_range1: '',
			margin2: '',
			interval2: '',
			threshold2: '',
			starting_range2: '',
			ending_range2: '',
			margin3: '',
			interval3: '',
			threshold3: '',
			starting_range3: '',
			ending_range3: '',
			margin4: '',
			interval4: '',
			threshold4: '',
			starting_range4: '',
			ending_range4: '',
			pulseDuration: 0,
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.selectedUserId;
		const parent = this.props.app.userId;
		// Get Plan List
		Fetcher.get(apiPath + '/api/obd/plans/list/' + parent)
			.then(res => {
				this.setState({
					planList: res
				})
			})
			.catch(err => { console.log('Error in fetching Plans', err) });
			
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					emailid: res.emailid,
					number: res.number,
					address: res.address,
					company: res.company,
					pincode: res.pincode,
					planId: res.planId,
					planName: res.planName,
					userType: res.userType,
					type: res.userType==='user' ? 'User' : 'Reseller',
					accountType: res.accountType,
					account: res.accountType===0 ? 'Promotional': 'Transactional',
					channels: res.channels,
					expiryDate: res.expiryDate,
					parentModuleList: res.modules,
					isSub : res.sub,
					settings:res.settings,
					// pulseDuration: res.pulseDuration,
				})
				console.log("Setting : "+this.state.setting);
				if(this.state.isSub === 1){
					this.setState({ isSubValue: true });
				}
				for (let i = 1; i <= 4; i++) {
					const tier = this.state.settings[`tier${i}`];
					if (tier) {
						this.setState({
							[`Setting${i}`]: true,
							[`margin${i}`]: tier.margin,
							[`interval${i}`]: tier.interval || '',
							[`threshold${i}`]: tier.threshold,
							[`starting_range${i}`]: tier.startRange || '',
							[`ending_range${i}`]: tier.endRange || ''
						});
					}
				}				
			})
			.catch(err => { console.log('Error in fetching Users data', err) });

			Fetcher.get(apiPath + '/api/obd/user/profile/' + parent)
			.then(res => {
				this.setState({
					parentModuleList: res.modules,
					pulseDuration: res.pulseDuration,
					showSubmission: res.sub === 1 ? false : true,
				})
			})
			.catch(err => { console.log('Error in fetching Parent data', err) });

		// Get User Module List
		Fetcher.get(apiPath + '/api/obd/module/list/' + userId)
		.then(res => {
			this.setState({
				moduleRows: res
			})
		})
		.catch(err => { console.log('Error in fetching Modules', err) });

		// Get Module List
		Fetcher.get(apiPath + '/api/obd/module')
		.then(res => {
			this.setState({
				allocatedmoduleList: res
			})
		})
		.catch(err => { console.log('Error in fetching Modules', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}
	addZero = (i) => {
		if (i < 10) {
			i = "0" + i;
		}
		return i;
	}
	sendLaterDateChange = date => {
		this.setState({
			sendDate: date,
			expiryDate: date
		});
	};
	handleAddModuleRow = () => {
		if (this.state.moduleId !== '') {
			let moduleName = this.state.moduleId.substring(this.state.moduleId.lastIndexOf(',')+1,this.state.moduleId.length);
			let moduleId = this.state.moduleId.substring(0,this.state.moduleId.lastIndexOf(','));
			const item = {
				moduleId: moduleId,
				moduleName: moduleName
			};
			if(!this.state.moduleRows.length)
			{
				this.state.moduleRows = [];
			}
			const checkModule = obj => obj.moduleName === moduleName;
			if(this.state.moduleRows.some(checkModule))
			{
					Notify.notify('This module is already selected');
					return;
			}
			//const checkGroup = obj => obj.groupId === groupId;
			/*if(this.state.groupRows.some(checkGroup))
			{
					Notify.notify('This Group is already selected');
					return;
			}*/
			
			this.state.moduleRows.push(item);
			this.setState({
				moduleRows: this.state.moduleRows
			});
			this.state.moduleList = {
				"moduleList": this.state.moduleRows
			}
		}
		else {
			Notify.notify("Please select Module");
		}
	};
	handleRemoveModuleRow = (index) => {

		let arr1 = this.state.moduleRows;
		let arr2= this.state.moduleRows.splice(index,1);
		let arr3 = [];
		 for(let i=0;i<arr1.length;i++){
			if(arr1[0].moduleId === arr2[0].moduleId){
				continue;
			}else{
                arr3.push(arr1[i]);
			}
		 }
		 this.setState({
		  moduleRows: arr3
		 });
	};
	handleChangeModuleRow = idx => e => {
		const { name, value } = e.target;
		const moduleRows = [...this.state.moduleRows];
		moduleRows[idx] = {
			[name]: value
		};
		this.setState({
			moduleRows
		});
	};

	updateUser = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		let date = '';
		let sendDateTime='';
		
		/*if(state.moduleRows.length == 0)
		{
			this.state.moduleList = {
				"moduleList": ""
			}
			//Notify.notify('Please select atleast one module');
			//return;
		}*/
		if(state.moduleList !== '' || state.moduleRows.length)
		{
			this.state.moduleList = {
				"moduleList": this.state.moduleRows
			}
		}
		else
		{
			this.state.moduleList = {
				"moduleList": []
			}
		}
		if (state.sendDate == null) {
			Notify.notify('Please Select Expiry Date');
			return;
		}
		if(state.sendDate === '')
		{
			sendDateTime = state.expiryDate;
		}
		else
		{
			date = state.sendDate;
			
			sendDateTime = this.addZero(date.getFullYear());
			sendDateTime += "-";
			sendDateTime += this.addZero((date.getMonth() + 1));
			sendDateTime += "-"
			sendDateTime += this.addZero(date.getDate());
			sendDateTime += " 23:59:59"
			/*sendDateTime += this.addZero(date.getHours());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getMinutes());
			sendDateTime += ":"
			sendDateTime += this.addZero(date.getSeconds());*/
		}

		let sValue = 0;
		if(this.state.isSubValue){
			sValue = 1;
		}

		const updatedSettings = {};

		for (let i = 1; i <= 4; i++) {
			// if (i === 1 ){
            // 	const planList = this.state.planList;
		    // 	const matchingPlan = planList.find(plan => plan.planId == this.state.planId);
			// 	if (matchingPlan && matchingPlan.pulseDuration != 1){
			// 		this.setState({ Setting1: false });
			// 		continue;
			// 	}
			// }
			const setting = state[`Setting${i}`];
			if (setting) {
				updatedSettings[`tier${i}`] = {
					margin: state[`margin${i}`],
					interval: state[`interval${i}`] || "",
					threshold: state[`threshold${i}`],
					startRange: state[`interval${i}`] ? "" : state[`starting_range${i}`],
					endRange: state[`interval${i}`] ? "" : state[`ending_range${i}`]
				};
				if (parseInt(state[`starting_range${i}`]) >= parseInt(state[`ending_range${i}`])) {
					alert(`For Setting${i}, starting range cannot be greater than or equal to ending range`);
					return;
				}
				// if (i === 1 && state.pulseDuration > 1) {
				// 	alert('User Pulse Duration is Greater than 1 while applying setting for Setting1');
				// 	return;
				// }
			}
		}

		if(Object.keys(updatedSettings).length !== 0){
            
			const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime,
			"moduleRows": JSON.stringify(this.state.moduleList),
			sub : sValue,
			settings :updatedSettings,
			isSetting : true
		}
		this.saveData(data, false);

		}
        else{
		
		const data = {
			userId: this.props.selectedUserId,
			name: state.name,
			emailid: state.emailid,
			number: state.number,
			address: state.address,
			company: state.company,
			pincode: state.pincode,
			planId: state.planId,
			accountType: state.accountType,
			userType: state.userType,
			channels: state.channels,
			expiryDate: sendDateTime,
			"moduleRows": JSON.stringify(this.state.moduleList),
			sub : sValue,
			isSetting : false
		}
		this.saveData(data, false);
	  }

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let modalHeading;
				let redirectToUserManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'User Updation Failed') {
					redirectToUserManagement = false;
				} else {
					redirectToUserManagement = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToUserManagement: redirectToUserManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Account', err)
				Notify.notify('Error in Updating Account');
			});

	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
	}

	toggleCheckedChange = (e, name) => {
		this.setState({
			[name]: e.target.checked,
		});
	};

	renderSettingOption = (optionName) => {
		const isAdmin = this.props.app.role === 'ROLE_SADMIN';
		const optionState = this.state[optionName];
		const marginValue = this.state[`margin${optionName.slice(-1)}`];
		const thresholdValue = this.state[`threshold${optionName.slice(-1)}`];
		const isTier2or3 = optionName === 'Setting2' || optionName === 'Setting3';
		const displayName = `Setting${optionName.slice(-1)}`;
		let intervalValue;

        if (!isTier2or3) {
          intervalValue = this.state[`interval${optionName.slice(-1)}`];
        }
	
		return (
			<div>
				<Row>
					{isAdmin && (
						<Col sm={3}>
							<Form.Group className="form-group">
								<Form.Check
									checked={optionState}
									onChange={(e) => this.toggleCheckedChange(e, optionName)}
									custom
									inline
									name={optionName}
									label={`${displayName}`}
									id={optionName}
									type="checkbox"
								/>
							</Form.Group>
						</Col>
					)}
				</Row>
				<Row>
					{optionState && isAdmin && (
						<>
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Select
										className="dd"
										required
										name={`margin${optionName.slice(-1)}`}
										value={marginValue}
										onChange={(e) => this.setvalue(e)}
										as="select"
									>
										<option value="">Select Margin</option>
										{[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((percent, index) => (
											<option key={index} value={index + 1}>{percent}%</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							{isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`starting_range${optionName.slice(-1)}`}
                                            value={this.state[`starting_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"        
                                        >        
                                            <option value="">Starting Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                                <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}        
                                        </Form.Select>
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
										<Form.Select
                                            className="dd"
                                            required
                                            name={`ending_range${optionName.slice(-1)}`}
                                            value={this.state[`ending_range${optionName.slice(-1)}`]}
                                            onChange={(e) => this.setvalue(e)}
                                            as="select"
                                        >
                                            <option value="">Ending Range</option>
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
                                              <option key={seconds} value={seconds}>{seconds} sec</option>
                                            ))}
                                        </Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							{!isTier2or3 && (
								<>
									<Col sm={4}>
										<Form.Group className="form-group required control-label">
											<Form.Select
												className="dd"
												required
												name={`interval${optionName.slice(-1)}`}
												value={intervalValue}
												onChange={(e) => this.setvalue(e)}
												as="select"
											>
												<option value="">Select Interval</option>
												{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((seconds) => (
													<option key={seconds} value={seconds}>{seconds} sec</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
								</>
							)}
							<Col sm={4}>
								<Form.Group className="form-group required control-label">
									<Form.Control
										required
										name={`threshold${optionName.slice(-1)}`}
										type="number"
										placeholder="Threshold"
										onChange={(e) => this.setvalue(e)}
										value={thresholdValue}
									/>
								</Form.Group>
							</Col>
						</>
					)}
				</Row>
			</div>
		);
	};
    
	render() {
		if (this.state.redirectToUserManagement === true) {
			return <Redirect to={'/user-list/'} />
		}
		const allocatedmoduleList = cloneDeep(this.state.allocatedmoduleList);
		let moduleDropdown = '';
		if (!isEmpty(allocatedmoduleList) && (!('error' in allocatedmoduleList))) {
			moduleDropdown = Array.isArray(allocatedmoduleList) ? allocatedmoduleList.map((obj, index) => {
				return <option value={obj.moduleId+','+obj.moduleName} key={`location${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			moduleDropdown = <option value="" >No module found</option>
		}

		const parentModuleList = cloneDeep(this.state.parentModuleList);
		let parentModuleDropdown = '';
		if (!isEmpty(parentModuleList) && (!('error' in parentModuleList))) {
			parentModuleDropdown = Array.isArray(parentModuleList) ? parentModuleList.map((obj, index) => {
				return <option value={obj.moduleId+','+obj.moduleName} key={`location${index}`} >{obj.moduleName}</option>
			}) : []

		} else {
			parentModuleDropdown = <option value="" >No module found</option>
		}

		const moduleRows = cloneDeep(this.state.moduleRows);
		const allocatedModulesTable = Array.isArray(moduleRows) ? moduleRows.map((item, index) => {
			return <tr key={index}>
				<td>{moduleRows[index].moduleName}</td>
				<td>
					<i onClick={() => this.handleRemoveModuleRow(index)} className="fa fa-minus-circle"></i>
				</td>
			</tr>;
		}) : [];
		let sendLaterDatepicker='';
		const filterTime = (date) => {
			const isPastTime = new Date().getTime() > date.getTime();
			return !isPastTime;
			};
		sendLaterDatepicker =
				<Col sm={3}>
					<Form.Group className="form-group required">
						<Form.Label>User Expiry</Form.Label>
							<DatePicker
								className="form-control"
								selected={this.state.expiryDate ? new Date(this.state.expiryDate) : this.state.sendDate}
								timeCaption="Time"
								minDate={new Date()}
								dateFormat="yyyy-MM-dd"
								onChange={this.sendLaterDateChange}
								filterTime={filterTime}
							/>
					</Form.Group>
				</Col>


		const planList = cloneDeep(this.state.planList);
		let matchingPlan;
		if(planList && planList.length > 0){
			matchingPlan = planList.find(plan => plan.planId == this.state.planId);
		} 
		let planDropdown = '';
		if (!isEmpty(planList) && (!('error' in planList))) {
			planDropdown = Array.isArray(planList) ? planList.map((obj, index) => {
				return <option value={obj.planId} key={`planid${index}`} >{obj.planName}</option>
			}) : []

		} else {
			planDropdown = <option>No Plan</option>
		}
			
		return (

			<Form method="post" onSubmit={this.updateUser}>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Update User</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Name</Form.Label>
											<Form.Control required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Email ID</Form.Label>
											<Form.Control required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Mobile Number</Form.Label>
											<Form.Control required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Address</Form.Label>
											<Form.Control required name="address" type="text" placeholder="Address" onChange={e => this.setvalue( e )} value={this.state.address} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Pincode</Form.Label>
											<Form.Control required name="pincode" type="text" placeholder="Pincode" onChange={e => this.setvalue( e )} value={this.state.pincode} />
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Company</Form.Label>
											<Form.Control required name="company" type="text" placeholder="Company Name" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Update Plan</Form.Label>
											<Form.Select required name="planId" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.planId}>{this.state.planName}</option>
												{planDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
										<Form.Label>Update User Type</Form.Label>
											<Form.Select required name="userType" onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.userType}>{this.state.type}</option>
												<option value="reseller">Reseller</option>
												<option value="user">User</option>
											</Form.Select>
										</Form.Group>
									</Col>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Update Account Type</Form.Label>
											<Form.Select required name="accountType" onChange={e => this.setvalue(e)} as="select">
												<option value="{this.state.accountType}">{this.state.account}</option>
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={3}>
										<Form.Group>
											<Form.Label>Channels</Form.Label>
											<Form.Control required name="channels" type="text" value={this.state.channels} placeholder="No. of Channels" onChange={e => this.setvalue( e )}  />
										</Form.Group>
									</Col>
									{sendLaterDatepicker}
								</Row>
								<Row>
								{(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className='form-group'>
											<Form.Select name="moduleId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Module</option>
												{moduleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.props.app.role === 'ROLE_ADMIN') && <Col sm={3}>
										<Form.Group className='form-group'>
											<Form.Select name="moduleId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Module</option>
												{parentModuleDropdown}
											</Form.Select>
										</Form.Group>
									</Col>}
									<Col sm={1}>
										<i onClick={this.handleAddModuleRow} className="fa fa-plus-circle plus-icon"></i>
									</Col>
								<Col sm={4}>
										<div className="react-bootstrap-table">

											<table className="table table-striped table-bordered table-sm widgetlist-table">
												<thead>
													<tr>
														<th>Module</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{allocatedModulesTable}
												</tbody>
											</table>
										</div>
									</Col>
									<Col sm={1}>
										
									</Col>
								</Row>

								<Row>
									{(this.props.app.role === 'ROLE_SADMIN' || this.state.showSubmission) && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.isSubValue} onChange={( e ) => this.toggleChange( e, 'isSubValue' )} custom inline name="isSubValue" label="Submission" id="isSubValue" type="checkbox" />
										</Form.Group>
										</Col>}

									{/* {(this.props.app.role === 'ROLE_SADMIN') && <Col sm={3}>
										<Form.Group className="form-group" >
											<Form.Check checked={this.state.setting} onChange={( e ) => this.toggleChange( e, 'setting' )} custom inline name="setting" label="Setting" id="setting" type="checkbox" />
										</Form.Group>
									</Col>} */}
								</Row>

								<Row>
								<Row>
								  {/* {this.renderSettingOption('Setting1')} */}
								  {((matchingPlan && matchingPlan.pulseDuration == 1) || matchingPlan === undefined ) && this.renderSettingOption('Setting1')}
								  {this.renderSettingOption('Setting2')}
								  {this.renderSettingOption('Setting3')}
								  {this.renderSettingOption('Setting4')}
								</Row>
								</Row>

								{/* <Row>
									
									{(this.state.setting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Label>Margin</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Select name="margin" onChange={e => this.setvalue(e)} as="select">
												{(!this.state.margin) ?<option value="">Select Margin</option> :
												 <option value={this.state.margin}>{this.state.margin}0%</option>
												}
												<option value="1">10%</option>
												<option value="2">20%</option>
												<option value="3">30%</option>
												<option value="4">40%</option>
												<option value="5">50%</option>
												<option value="6">60%</option>
												<option value="7">70%</option>
												<option value="8">80%</option>
												<option value="9">90%</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.state.setting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Label>Interval</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Select required name="interval" value={this.state.interval} onChange={e => this.setvalue(e)} as="select">
												{(!this.state.interval) ?<option value="">Select Interval</option> :
												 <option value={this.state.interval}>{this.state.interval} sec</option>
												}
												<option value={this.state.interval}>{this.state.interval} sec</option>
												<option value="1">1 sec</option>
												<option value="2">2 sec</option>
												<option value="3">3 sec</option>
												<option value="4">4 sec</option>
												<option value="5">5 sec</option>
												<option value="6">6 sec</option>
												<option value="7">7 sec</option>
												<option value="8">8 sec</option>
												<option value="9">9 sec</option>
											</Form.Select>
										</Form.Group>
									</Col>}
									{(this.state.setting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={4}>
										<Form.Label>Threshold</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="threshold" type="number" placeholder="Threshold" onChange={e => this.setvalue(e)} value={this.state.threshold} />
										</Form.Group>
									</Col>}
								</Row>

								<Row>
									{ (this.state.setting) && (this.props.app.role === 'ROLE_SADMIN') && <Col sm={6}>
										<Form.Label>Tier</Form.Label>
										<Form.Group className="form-group required control-label">
											<Form.Select required name="tier" value={this.state.tier} onChange={e => this.setvalue(e)} as="select">
												<option value={this.state.tier}>Tier {this.state.tier}</option>
												<option value="1">Tier 1</option>
												<option value="2">Tier 2</option>
											</Form.Select>
										</Form.Group>
									</Col>}
								</Row> */}
								<Button className="btn-round" variant="primary" type="submit">Update User</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateUser;