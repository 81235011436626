import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class SMSCampaignReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			campaignSummary: {
				campaignName: '',
				campaignStatus: '',
				scheduleTime: '',
				numbersUploaded: '',
				smsSubmitted: '',
				smsDelivered: '',
				smsFailed: '',
				showModal: false,
				modalHeading: 'Status',
				modalContent: '',
			},
			campaignDetail: [],
			loading: false
		}
	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const campaignId = this.props.campaignId;
		Fetcher.get(apiPath + '/app/sms/campaign/summary/' + campaignId)
			.then(res => {
				this.setState({
					campaignSummary: res
				})
			})
			.catch(err => { console.log('Error in fetching Campaign Summary', err) });

	}
	setMessageShow = (status) => {
		this.setState({
			showModal: status
		});
	}

	loadReport = () => {
		const apiPath = this.props.app.apiPath;
		const campaignId = this.props.campaignId;
		this.setState({ loading: true });
		Fetcher.get(apiPath + '/app/sms/campaign/report/' + campaignId)
			.then(res => {
				if (!isEmpty(res.error)) {
					this.setState({
						showModal: true,
						modalHeading: 'Error',
						modalContent: res.error,
						loading: false
					})
				} else {
					this.setState({
						campaignDetail: res,
						loading: false
					})
				}

			})
			.catch(err => { console.log('Error in fetching Campaign Report', err) });


	}
	render() {

		// Campaign Report
		const campaigns = this.state.campaignDetail;
		const columns = [
			{
				dataField: 'id',
				text: 'Serial No.',
				sort: false,
				hidden: true
			},
			{
				dataField: 'msisdn',
				text: 'Mobile No.',
				sort: true,
				searchable: true
			}, {
				dataField: 'cli',
				text: 'Sender Id',
				sort: true,
				searchable: true
			}, {
				dataField: 'submitTime',
				text: 'Submit Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'deliveryTime',
				text: 'Delivery Time',
				sort: true,
				searchable: false
			}, {
				dataField: 'deliveryStatus',
				text: 'Delivery Status',
				sort: true,
				searchable: false
			}];

		const products = campaigns.map((campaign, index) => {
			return {
				id: (index + 1) + '.',
				cli: campaign.cli,
				msisdn: campaign.msisdn,
				deliveryTime: campaign.deliveryTime,
				submitTime: campaign.submitTime,
				deliveryStatus: campaign.deliveryStatus
			};
		})
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;
		const defaultSorted = [{
			dataField: 'submitTime',
			order: 'desc'
		}];

		let status = '';

		switch (this.state.campaignSummary.campaignStatus) {
			case 0:
				status = 'Ready';
				break;
			case 1:
				status = 'Running';
				break;
			case 2:
				status = 'Complete';
				break;
			case 3:
				status = 'Low Balance';
				break;
			case 4:
				status = 'Error';
				break;
			default:
				status = 'Ready';
				break;

		}
		const paginationOption = {
			custom: true,
			totalSize: products.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<div>
				<Card>
					<Card.Header>SMS Campaign Summary</Card.Header>
					<Card.Body>
						<div className="campaign-summary-table-cont table-responsive ">

							<table className="table table-striped table-bordered table-sm campaignsummary-table campaignlist-table">
								<thead>
									<tr>
										<th>Campaign Name</th>
										<th>Campaign Status</th>
										<th>Schedule Time</th>
										<th>Numbers Uploaded</th>
										<th>SMS Submitted</th>
										<th>SMS Delivered</th>
										<th>SMS Failed</th>
										<th>Detailed Report</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{this.state.campaignSummary.campaignName}</td>
										<td>{status}</td>
										<td>{this.state.campaignSummary.scheduleTime}</td>
										<td>{this.state.campaignSummary.numbersUploaded}</td>
										<td>{this.state.campaignSummary.smsSubmitted}</td>
										<td>{this.state.campaignSummary.smsDelivered}</td>
										<td>{this.state.campaignSummary.smsFailed}</td>
										<td><a href="#" className="detailed-report-link" onClick={this.loadReport}><i className="fa fa-eye"></i>Detailed Report</a></td>
									</tr>
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>
				{this.state.loading && <div className="fetching-data"><img src="/assets/loading.gif" /></div>}

				{!isEmpty(this.state.campaignDetail) && <Card>
					<Card.Header>Detailed Report</Card.Header>
					<Card.Body>
						<div className="campaign-list-table-cont  ">
							{/* <h3>Campaign Report</h3> */}
							<PaginationProvider
								pagination={paginationFactory(paginationOption)}
							>
								{
									({
										paginationProps,
										paginationTableProps
									}) => (
										<ToolkitProvider
											keyField="id"
											data={products}
											columns={columns}
											search
											bootstrap4
										>
											{
												props => (
													<div className="">
														Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
														<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
														<ClearSearchButton className="btn-primary" {...props.searchProps} />
														<ExportCSVButton className=" export-csv btn-primary" {...props.csvProps}>Download Report</ExportCSVButton>
														<hr />
														<BootstrapTable bordered={true} striped={true} condensed defaultSorted={defaultSorted} wrapperClasses="table-responsive" classes="campaignlist-table"  {...paginationTableProps}  {...props.baseProps} />
														<PaginationListStandalone {...paginationProps} />
													</div>
												)
											}
										</ToolkitProvider>
									)
								}
							</PaginationProvider>

						</div>
					</Card.Body>
				</Card>
				}

				<Modal size="sm" show={this.state.showModal} onHide={() => this.setMessageShow(false)}>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-sm">
							{this.state.modalHeading}
						</Modal.Title>

					</Modal.Header>
					<Modal.Body>{this.state.modalContent} </Modal.Body>
				</Modal>
			</div>
		);
	}
}


export default SMSCampaignReport;