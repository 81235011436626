import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { Redirect } from "react-router-dom";


// import "./index.css";
class UpdateDialer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialerid: '',
			dialerName: '',
			dialerIP: '',
			dialerPort: '',
			channelReduction: '',
			cpsReduction: '',
			cps: '',
			totalChannels: '',
			origCps: '',
			origTotalChannels: '',
			activeCli: '',
			redirectToDialerManagement: false
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const dialerId = this.props.selectedDialerId;
		Fetcher.get(apiPath + '/api/obd/dialer/detail/' + dialerId)
			.then(res => {
				this.setState({
					dialerName: res.dialerName,
					dialerIP: res.dialerIP,
					dialerPort: res.dialerPort,
					cps: res.cps,
					totalChannels: res.totalChannels,
					origCps: res.cps,
					origTotalChannels: res.totalChannels,
					channelReduction: res.channelReduction,
					cpsReduction: res.cpsReduction
				})
			})
			.catch(err => { console.log('Error in fetching Dialer data', err) });
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToDialerManagement: this.state.redirectToDialerManagement
		});
	}


	updateDialer = (event) => {
		event.preventDefault();
		const state = cloneDeep(this.state);
		/*if (state.cps > this.state.origCps) {
			Notify.notify('CPS to be updated is greater than Available CPS');
			return;
		}
		if (state.totalChannels > this.state.origTotalChannels) {
			Notify.notify('Channels to be updated is greater than Available Channels');
			return;
		}*/
		const data = {
			dialerId: this.props.selectedDialerId,
			dialerName: state.dialerName,
			dialerIP: state.dialerIP,
			dialerPort: state.dialerPort,
			cpsReduction: state.cps,
			channelReduction: state.totalChannels
		}
		this.saveData(data);

	}
	saveData = (data) => {
		return Fetcher.post(this.props.app.apiPath + '/api/obd/dialer/update', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let redirectToDialerManagement;
				if (res.message === 'Parameters Incorrect' || res.message === 'Request Failed!! Please try again') {
					Notify.notify(res.message);
					redirectToDialerManagement = false;
				} else {
					redirectToDialerManagement = true;
					Notify.notify(res.message);

				}
				this.setState({
					redirectToDialerManagement: redirectToDialerManagement
				})

			})
			.catch(err => {
				console.log('Error in Updating Dialer', err);
				Notify.notify('Error in Updating Dialer');
				
			});

	}

	render() {
		if (this.state.redirectToDialerManagement === true) {
			return <Redirect to={'/dialer-list/'} />
		}

		return (

			<Form method="post" onSubmit={this.updateDialer}>
				<Row>
					<Col sm={12}>
						<Card>
						<Card.Header>Update Dialer</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group>
										<Form.Label>Dialer Name</Form.Label>
											<Form.Control required name="dialerName" type="text" placeholder="Dialer Name" onChange={e => this.setvalue(e)} value={this.state.dialerName} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Dialer IP</Form.Label>
											<Form.Control required name="dialerIP" type="text" placeholder="IP" onChange={e => this.setvalue(e)} value={this.state.dialerIP} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Dialer Port</Form.Label>
											<Form.Control required name="dialerPort" type="text" placeholder="Port" onChange={e => this.setvalue(e)} value={this.state.dialerPort} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Dialer CPS</Form.Label>
											<Form.Control required name="cps" type="text" placeholder="Update CPS" onChange={e => this.setvalue(e)} value={this.state.cps} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Dialer Channels</Form.Label>
											<Form.Control required name="totalChannels" type="text" placeholder="Update Channels" onChange={e => this.setvalue(e)} value={this.state.totalChannels} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Update Dialer</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UpdateDialer;