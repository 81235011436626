import React, { Component } from 'react';
import { Form, Row, Col, Card, Button, Modal, Tabs, Tab, FormGroup } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import { cloneDeep, isEmpty } from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

class VoiceCampaignInfo extends Component {
	constructor(props) {
		super(props);

		this.baseFileInput = React.createRef();
		let sitePath = '';
		let filePath = '';

		const protocol = window.location.protocol;
		const slashes = protocol.concat("//");
		sitePath = slashes.concat(window.location.hostname);

		this.state = {
			campaignPrompts: [],
			campaignAgents: [],
			campaignSms: [],
			campaignName: '',
			templateId: '0',
			dtmf: '',
			agentNumber: '',
			agentDtmf: '',
			userId: props.app.userId,
			apiPath: props.app.apiPath,
			sitePath: sitePath,
			filePath: filePath,
			promptId: '',
			welcomeId: '',
			menuId: '',
			noinputId: '',
			wronginputId: '',
			thanksId: '',
			redirectToSummary: false,
			campaignCreated: false,
			smsLink: '',
			smsApiSuccess: '',
			oldSmsApiSuccess: '',
			smsApiFail:'',
			oldSmsApiFail: '',
			smsApiDtmf: '',
			oldSmsApiDtmf: '',
			smsText:'',
			connectEvent:'',
			dtmfEvent: '',
			failEvent:'',
			callDurationSMS: 0,
			oldCallDurationSMS: 0,
			trunk: '',
			channels: '',
			menuWaitTime: '',
			rePrompt: '',
			connectApiId: '',
			failApiId: '',
			dtmfApiId: '',
			retryInterval: '',
			retries: ''
		}
	}

	componentDidMount() {
		const campaignId = this.props.campaignId;
		const apiPath = this.state.apiPath;

		// Get Campaign Info
		Fetcher.get(apiPath + '/api/obd/campaign/info/' + campaignId)
			.then(res => {
				
				this.setState({
					campaignName: res.campaign.campaignName,
					validDtmf: res.campaign.dtmf,
					templateId: res.campaign.templateId,
					channels: res.campaign.channels,
					menuWaitTime: res.campaign.menuWaitTime,
					campaignPrompts: res.campaignPrompts,
					campaignAgents: res.campaignAgents,
					campaignSms: res.campaignSms,
					retryInterval: res.campaign.retryInterval,
					retries: res.campaign.retries
				})
			})
			.catch(err => { console.log('Error in fetching BaseUpload Files', err) });
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	editConnectSmsApi = async (event) => {
		event.preventDefault();
		if(this.state.smsText === '')
		{
			this.state.smsText = this.state.oldSmsApiSuccess;
		}
		const data = {
			"apiId": this.state.connectApiId,
			"smsApi": this.state.smsText,
			"callDurationSMS": (this.state.callDurationSMS === 0)?this.state.oldCallDurationSMS:this.state.callDurationSMS
		}
		this.handleEditSmsApi(data);
	}
	editFailSmsApi = async (event) => {
		event.preventDefault();
		if(this.state.smsText === '')
		{
			this.state.smsText = this.state.oldSmsApiFail;
		}
		const data = {
			"apiId": this.state.failApiId,
			"smsApi": this.state.smsText,
			"callDurationSMS": (this.state.callDurationSMS === 0)?this.state.oldCallDurationSMS:this.state.callDurationSMS
		}
		this.handleEditSmsApi(data);
	}
	editDtmfSmsApi = async (event) => {
		event.preventDefault();
		if(this.state.smsText === '')
		{
			this.state.smsText = this.state.oldSmsApiDtmf;
		}
		const data = {
			"apiId": this.state.dtmfApiId,
			"smsApi": this.state.smsText,
			"callDurationSMS": (this.state.callDurationSMS === 0)?this.state.oldCallDurationSMS:this.state.callDurationSMS
		}
		this.handleEditSmsApi(data);
	}

	handleEditSmsApi = async (data) => {

		const apiPath = this.state.apiPath;
		
		return Fetcher.post(apiPath + '/api/obd/campaign/sms/edit', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				let campaignCreated = false;
				if (res.message === 'Parameters Incorrect') {
					Notify.notify(res.message);
				} else {
					campaignCreated = true;
					Notify.notify(res.message);
				}
				this.setState({
					redirectToSummary: campaignCreated
				})
			})
			.catch(err => {
				console.log('Error in Add Campaign', err);
				Notify.notify('Error in Add Campaign');
			});
	}

	render() {
		// Redirect to Summary if Campaign Added successfully.
		if (this.state.redirectToSummary === true) {
			return <Redirect to='/voice-campaign-summary' />
		}
		//console.log( 'Add Campaign State', this.state );
		const campaignPrompts = cloneDeep(this.state.campaignPrompts);
		const campaignAgents = cloneDeep(this.state.campaignAgents);
		const campaignSms = cloneDeep(this.state.campaignSms);
		let promptsInfo = '';

		if (!isEmpty(campaignPrompts) && (!('error' in campaignPrompts))) {
			promptsInfo = campaignPrompts.map((fileObj, index) => {
				return <Row><Col sm={4}>
				<Form.Group>
					<Form.Label>Prompt Info</Form.Label>
					<Form.Control key={`prname${index}`} readOnly value={'Name: '+fileObj.promptName} type="text" />
					<Form.Control key={`prdur${index}`} readOnly value={'Duration: '+fileObj.promptDuration} type="text" />
				</Form.Group>
			</Col>
			</Row>
			})

		} else {
			promptsInfo = '';
		}

		if (!isEmpty(campaignSms) && (!('error' in campaignSms))) {
			campaignSms.map((fileObj, index) => {
			if(fileObj.smsEvent === 'CALL_CONNECTED')
			{
				this.state.connectEvent = fileObj.smsEvent;
				this.state.oldSmsApiSuccess = fileObj.smsText;
				this.state.connectApiId = fileObj.apiId;
				this.state.oldCallDurationSMS = fileObj.callDurationSMS;
			}
			else if(fileObj.smsEvent === 'CALL_FAILED')
			{
				this.state.failEvent = fileObj.smsEvent;
				this.state.oldSmsApiFail = fileObj.smsText;
				this.state.failApiId = fileObj.apiId;
			}
			else if(fileObj.smsEvent === 'VALID_DTMF')
			{
				this.state.dtmfEvent = fileObj.smsEvent;
				this.state.oldSmsApiDtmf = fileObj.smsText;
				this.state.dtmfApiId = fileObj.apiId;
			}
		})
		}
		let agentInfo = '';
		if (!isEmpty(campaignAgents) && (!('error' in campaignAgents))) {
			agentInfo = campaignAgents.map((fileObj, index) => {
				return <Row><Col sm={12}>
				<Form.Group>
					<Form.Label>Agent Info</Form.Label>
					<Form.Control readOnly key={`agentno${index}`} value={'Agent No.: '+fileObj.agentNumber} type="text" />
					<Form.Control readOnly key={`agentdtmf${index}`} value={'Agent DTMF: '+fileObj.agentDtmf} type="text" />
				</Form.Group>
			</Col>
			</Row>
			})

		} else {
			agentInfo = '';
		}
		
		var template = '';
		switch (this.state.templateId) {
			case 0:
				template = 'Simple IVR';
				break;
			case 1:
				template = 'DTMF';
				break;
			case 2:
				template = 'Call Patch';
				break;
			case 3:
				template = 'Custom IVR';
				break;
			default:
				template = '';
				break;
		}

		return (
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>Voice Campaign Info</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group controlId="campaign-name">
											<Form.Label>Campaign Name</Form.Label>
											<Form.Control readOnly value={this.state.campaignName} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group >
											<Form.Label>Campaign Type</Form.Label>
											<Form.Control readOnly value={template} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group >
											<Form.Label>Valid DTMF</Form.Label>
											<Form.Control readOnly value={this.state.validDtmf} type="text" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Menu Wait Time</Form.Label>
											<Form.Control readOnly value={this.state.menuWaitTime} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Channels</Form.Label>
											<Form.Control readOnly value={this.state.channels} type="text" />
										</Form.Group>
									</Col>
								</Row>
								<Row>{promptsInfo}</Row>
								<Row>
									<Col sm={4}>
										<Form.Group controlId="campaign-name">
											<Form.Label>No. of Retries</Form.Label>
											<Form.Control readOnly value={this.state.retries} type="text" />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group >
											<Form.Label>Retry Interval(In Mins)</Form.Label>
											<Form.Control readOnly value={this.state.retryInterval/60} type="text" />
										</Form.Group>
									</Col>
								</Row>
								<Form className="add-campaign-form" onSubmit={(e) => this.editConnectSmsApi(e)}>
								{(this.state.oldSmsApiSuccess !== '') && <Row>
									<Col sm={10}>
										<Form.Group >
											<Form.Label>{this.state.connectEvent}</Form.Label>
											<Form.Control required defaultValue={this.state.oldSmsApiSuccess} onChange={e => this.setvalue( e )} name="smsText" as="textarea" rows="3" />
										</Form.Group>
									</Col>
									<Col sm={2}>
											<Form.Group>
												<Form.Label>Select Duration</Form.Label>
												<Form.Select required name="callDurationSMS" onChange={e => this.setvalue(e)} as="select">
													<option value={this.state.oldCallDurationSMS}>{this.state.oldCallDurationSMS}</option>
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
													<option value="11">11</option>
													<option value="12">12</option>
													<option value="13">13</option>
													<option value="14">14</option>
													<option value="15">15</option>
													<option value="16">16</option>
													<option value="17">17</option>
													<option value="18">18</option>
													<option value="19">19</option>
													<option value="20">20</option>
													<option value="21">21</option>
													<option value="22">22</option>
													<option value="23">23</option>
													<option value="24">24</option>
													<option value="25">25</option>
													<option value="26">26</option>
													<option value="27">27</option>
													<option value="28">28</option>
													<option value="29">29</option>
													<option value="30">30</option>
													<option value="31">31</option>
													<option value="32">32</option>
													<option value="33">33</option>
													<option value="34">34</option>
													<option value="35">35</option>
													<option value="36">36</option>
													<option value="37">37</option>
													<option value="38">38</option>
													<option value="39">39</option>
													<option value="40">40</option>
													<option value="41">41</option>
													<option value="42">42</option>
													<option value="43">43</option>
													<option value="44">44</option>
													<option value="45">45</option>
													<option value="46">46</option>
													<option value="47">47</option>
													<option value="48">48</option>
													<option value="49">49</option>
													<option value="50">50</option>
													<option value="51">51</option>
													<option value="52">52</option>
													<option value="53">53</option>
													<option value="54">54</option>
													<option value="55">55</option>
													<option value="56">56</option>
													<option value="57">57</option>
													<option value="58">58</option>
													<option value="59">59</option>
													<option value="50">60</option>
												</Form.Select>
											</Form.Group>
									</Col>
								</Row>}
								{(this.state.oldSmsApiSuccess !== '') && <Row className="mb-2">
									<Button variant="primary" type="submit">Edit SMS API</Button>
								</Row>}
								</Form>
								<Form className="add-campaign-form" onSubmit={(e) => this.editFailSmsApi(e)}>
								{(this.state.oldSmsApiFail !== '') && <Row>
									<Col sm={10}>
										<Form.Group >
											<Form.Label>{this.state.failEvent}</Form.Label>
											<Form.Control required defaultValue={this.state.oldSmsApiFail} onChange={e => this.setvalue( e )} name="smsText" as="textarea" rows="3" />
										</Form.Group>
									</Col>
								</Row>}
								{(this.state.oldSmsApiFail !== '') && <Row className="mb-2">
									<Button variant="primary" type="submit">Edit SMS API</Button>
								</Row>}
								</Form>
								<Form className="add-campaign-form" onSubmit={(e) => this.editDtmfSmsApi(e)}>
								{(this.state.oldSmsApiDtmf !== '') && <Row>
									<Col sm={10}>
										<Form.Group >
											<Form.Label>{this.state.dtmfEvent}</Form.Label>
											<Form.Control required defaultValue={this.state.oldSmsApiDtmf} onChange={e => this.setvalue( e )} name="smsText" as="textarea" rows="3" />
										</Form.Group>
									</Col>
								</Row>}
								{(this.state.oldSmsApiDtmf !== '') && <Row>
									<Button variant="primary" type="submit">Edit SMS API</Button>
								</Row>}
								</Form>
								<Row>{agentInfo}</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
		);
	}
}

export default VoiceCampaignInfo;