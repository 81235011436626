import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Body from './Body';
import { Fetcher } from './Helpers/fetcher.js';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';

class Container extends Component {
	state = {
		mode: "production",
	    //apiPath: (window.location.host === 'obd2.expressivr.com') ? 'https://obd2api.expressivr.com' : 'https://obd2api.expressivr.com',
		//reportPath: 'https://obd2.expressivr.com',
		apiPath: (window.location.host === 'obd1.expressivr.com') ? 'https://obd1api.expressivr.com' : 'https://obd1api.expressivr.com',
		reportPath: 'https://obd1.expressivr.com',
		//apiPath: (window.location.host === 'obd.nspl.cloud') ? 'https://obdapi.nspl.cloud' : 'https://obdapi.nspl.cloud',
		//reportPath: 'https://obdapi.nspl.cloud',
		//apiPath: (window.location.host === 'movoice.ivrsms.com') ? 'https://movoiceapi.ivrsms.com' : 'https://movoiceapi.ivrsms.com',
		//reportPath: 'https://movoiceapi.ivrsms.com',
		siteUrl: window.location.origin,
		userId: Cookies.get('userId'),
		token: Cookies.get('token'),
		userRole: Cookies.get('role'),
		toggleSidebar: true,
		logo: false,
		company: false
	}

	componentDidMount() {

		const userId = Cookies.get('userId');
		const token = Cookies.get('token');
		const userRole = Cookies.get('role');

		const apiPath = this.state.apiPath;
		Fetcher.get(apiPath + '/api/obd/branding/' + window.location.hostname)
			.then(res => {
				if (typeof res.company !== 'undefined') {
					document.title = res.company;
				}
				this.setState({
					userId: userId,
					token: token,
					userRole: userRole,
					logo: (typeof res.logoUrl !== 'undefined') ? res.logoUrl : '',
					company: (typeof res.company !== 'undefined') ? res.company : ''
				})
			})
			.catch(err => {
				this.setState({
					userId: userId,
					token: token,
					userRole: userRole,
				})
				console.log('Error in fetching Branding', err)
			});
	}

	setUserToken = (userId, token, role) => {

		Cookies.set('userId', userId);
		Cookies.set('token', token);
		Cookies.set('role', role[0]);
		this.setState({
			userId: userId,
			token: token,
			userRole: role[0]
		})
	}

	logOutUser = () => {
		Cookies.set('userId', '');
		Cookies.set('token', '');
		Cookies.set('role', '');
		this.setState({
			userId: '',
			token: '',
			userRole: ''
		})

		window.location.href = "/";
	}
	toggleSidebar = () => {
		this.setState({
			toggleSidebar: !this.state.toggleSidebar,
		});
	}

	render() {

		console.log(this.state);
		let urlPrefix = '';
		if (this.state.mode === 'dev') {
			urlPrefix = '/build';
		}
		const app = {
			mode: this.state.mode,
			apiPath: this.state.apiPath,
			reportPath: this.state.reportPath,
			urlPrefix: urlPrefix,
			siteUrl: this.state.siteUrl,
			userId: this.state.userId,
			token: this.state.token,
			role: this.state.userRole,
			logo: this.state.logo,
			company: this.state.company
		}
		const toggleSidebarClass = this.state.toggleSidebar ? 'sidebar-show sidebar-lg-show' : '';
		return (
			<div className={`${toggleSidebarClass} app header-fixed sidebar-fixed aside-menu-fixed`}>
				<ToastContainer />
				{(this.state.userId !== '' && typeof this.state.userId !== 'undefined') && <Header logo={this.state.logo} toggleSidebar={this.toggleSidebar} logOutUser={this.logOutUser} app={app} />}
				<Body toggleSidebar={this.toggleSidebar} setUserToken={this.setUserToken} app={app} />
				{<Footer />}

			</div>

		);
	}
}
export default Container;