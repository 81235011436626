import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Fetcher } from "../Helpers/fetcher.js";
import './index.css';
class Header extends Component {

	constructor(props) {
		super(props);
	this.state = {
		name: '',
		username: '',
		emailid: '',
		number: '',
		company: '',
		planName: '',
		creditsAvailable: ''
	};
}
componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					username: res.username,
					emailid: res.emailid,
					number: res.number,
					company: res.company,
					planName: res.planName,
					creditsAvailable: res.credits
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });
		}

		render(){
	return (
		<header className="app-header navbar">
			{this.props.app.userId && <div className="nav-desktop"><a className="  navbar-brand" href={this.props.app.siteUrl}>
				{this.props.logo && <img className="navbar-brand-full" src={this.props.logo} alt=" Logo" />}
				{this.props.logo && <img className="navbar-brand-minimized" src={this.props.logo} width="30" alt=" Logo" />}
			</a>
				<button onClick={this.props.toggleSidebar} className="navbar-toggler sidebar-toggler d-lg-none " type="button" data-toggle="sidebar-show">
					<span className="navbar-toggler-icon"></span>
				</button></div>}


			{this.props.app.userId && <div className="nav-mobile"> <a className="  navbar-brand" href={this.props.app.siteUrl}>
				{this.props.logo && <img className="navbar-brand-full" src={this.props.logo} alt=" Logo" />}
				{this.props.logo && <img className="navbar-brand-minimized" src={this.props.logo} width="30" height="30" alt=" Logo" />}
			</a><button onClick={this.props.toggleSidebar} className="navbar-toggler sidebar-toggler d-lg-none " type="button" data-toggle="sidebar-show">
					<span className="navbar-toggler-icon"></span>
				</button></div>}
			{this.props.app.userId && <Nav defaultActiveKey="/logout" as="ul">
				{this.props.app.role !== 'ROLE_SADMIN' && <Nav.Item as="li">
					<Nav.Link href="#">Balance(Rs) : {this.state.creditsAvailable}</Nav.Link>
				</Nav.Item>}
				<Nav.Item as="li">
					<Nav.Link href="/user-profile"><i className="fa fa-user" aria-hidden="true"></i> {this.state.username}</Nav.Link>
				</Nav.Item>
				<Nav.Item as="li">
					<Nav.Link onClick={this.props.logOutUser} href=""><i className="fa fa-sign-out" aria-hidden="true"></i>Logout</Nav.Link>
				</Nav.Item>
			</Nav>}
		</header>
	);
		}

}

export default Header;