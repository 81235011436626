import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Fetcher } from "../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../Helpers/notification.js";
import { cloneDeep, isEmpty, isNull } from 'lodash';

const paginationOption = {
    custom: true,
    totalSize: 0,
    sizePerPage: 10,
    sizePerPageList: [10, 25, 50, 100]
}

const defaultSorted = [{
    dataField: 'userId',
    order: 'desc'
}]

// getSummary = () => {
// 		const apiPath = this.props.app.apiPath;
// 		const userId = this.props.app.userId;

//         const data = {
//             username:"",
//             startDate : "",
//             endDate : ""
//         }
		
//        return Fetcher.post(this.props.app.apiPath + '/api/obd/reseller/summary', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
// 			.then(res => {
// 				Notify.notify(res.message);
// 				if(res.message === 'User Created Successfully')
// 				{
// 					this.setState({
// 						redirectFlag: true
// 					})
// 				}
// 			})
// 			.catch(err => {
// 				console.log('Error in Registering Account', err)
// 				Notify.notify('Error in Registering Account');
// 			});

// 	}

const { ExportCSVButton } = CSVExport;

export default class ResellerSummary extends Component{

    constructor(props){

        super(props);

        this.state = {
            list: [],
            summaryList: [],
            username: '',
            startDate: '',
            endDate: '',
            noDataDisplayMsg: '',
            resellerUserList : [],
            userParentType : '',
        }

    }

    handleStartDateChange = (date) => {
               this.setState({
                   startDate: date,
            });
     };

     handleEndDateChange = (date) => {
               this.setState({
                   endDate: date,
            });
     };


     setvalue = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount() {
		this.getSummary();
        
        this.getResellersummaryUsers();

        this.getUserProfile();

	}

    getUserProfile = () => {

        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/'+userId)
			.then(res => {
				this.setState({
					userParentType : res.parentType
				})
			})
			.catch(err => { console.log('Error in fetching user profile', err) });

    }

    getResellersummaryUsers = () => {
        
        const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/reseller/users/'+userId)
			.then(res => {
				this.setState({
					resellerUserList: res.sort( (val1,val2) => {
                        if(val1.username > val2.username) return 1;
                        else if(val1.username < val2.username) return -1;
                        else return 0;
                    } )
				})
			})
			.catch(err => { console.log('Error in fetching Reseller Users List', err) });

    }

    getSummary = () => {
        const userId = this.props.app.userId;
        const data = {
            userId:userId,
            startDate : this.state.startDate,
            endDate : this.state.endDate,
            username: this.state.username
        }
		
       return Fetcher.post(this.props.app.apiPath + '/api/obd/reseller/summary', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
                console.log(res);
				this.setState({
					summaryList: res
				})
			})
			.catch(err => {
				console.log('Error in Registering Account', err)
				Notify.notify('Error in Registering Account');
			});

	}

    formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

    searchCdr = async (e) => {
        e.preventDefault();
        let sDate = "";
        let eDate ="";
        if(this.state.startDate === ""){
                 sDate = this.state.startDate
        }else{
            sDate =  this.formatDate(this.state.startDate)
        }
        if(this.state.endDate === ""){
                 eDate = this.state.endDate
        }else{
            eDate =  this.formatDate(this.state.endDate)
        }

        const userId = this.props.app.userId;

        const data = {
            username: this.state.username,
            startDate: sDate,
            endDate: eDate,
            userId: userId
        }

        return Fetcher.post(this.props.app.apiPath + '/api/obd/reseller/summary', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
            .then(res => {
                this.setState({'summaryList': res});
                if(res.length == 0) this.setState({noDataDisplayMsg: 'No records found'});
            })
            .catch(err => {
                console.log('Error in fetching call detail records: ', err);
                Notify.notify('Error in fetching call detail records');
            });
    }

    
    render() {

        console.log("********"+this.state.userParentType);
        const list = this.state.list;
        const summaryList = this.state.summaryList;
        let columns = [];
        if(this.state.userParentType === "reseller") {
		 columns = [
            {
                dataField: 'reseller1_username',
                text: 'Reseller2 Username',
                sort: true,
                searchable: true
            },            {
                dataField: 'reseller1_plan',
                text: 'Reseller2 Plan',
                sort: true,
                searchable: true
            },
            {
                dataField: 'username',
                text: 'Username',
                sort: true,
                searchable: true
            },
            {
                dataField: 'pulseduration',
                text: 'Pulse Duration',
                sort: false,
                searchable: false
            },
            {
                dataField: 'pulse_rate',
                text: 'Pulse Price',
                sort: false,
                searchable: false
            },
            {
                dataField: 'campaign_name',
                text: 'Campaign Name',
                sort: true,
                searchable: true
            },
            {
                dataField: 'campaign_start_date',
                text: 'Campaign Start Date',
                sort: false,
                searchable: false
            },
            {
                dataField: 'campaign_end_date',
                text: 'Campaign End Date',
                sort: false,
                searchable: false
            },
            {
                dataField: 'campaign_status',
                text: 'Campaign Status',
                sort: true,
                searchable: true
            },
            {
                dataField: 'calls_dialed',
                text: 'Calls Dialed',
                sort: false,
                searchable: false
            },
            {
                dataField: 'connected_calls',
                text: 'Connected Calls',
                sort: false,
                searchable: false
            },
            {
                dataField: 'reseller1_pulse',
                text: 'Reseller2 Pulse',
                sort: false,
                searchable: false
            },
            {
                dataField: 'user_pulse',
                text: 'User Pulse',
                sort: false,
                searchable: false
            },
            {
                dataField: 'sms_count',
                text: 'User SMS Count',
                sort: false,
                searchable: false
            }
        ];}
        else{

            columns = [
            {
                dataField: 'reseller2_username',
                text: 'Reseller1 Username',
                sort: true,
                searchable: true
            },
            {
                dataField: 'reseller2_plan',
                text: 'Reseller1 Plan',
                sort: true,
                searchable: true
            },
            {
                dataField: 'reseller2_pulse',
                text: 'Reseller1 Pulse',
                sort: false,
                searchable: false
            },
            {
                dataField: 'reseller1_username',
                text: 'Reseller2 Username',
                sort: true,
                searchable: true
            },            {
                dataField: 'reseller1_plan',
                text: 'Reseller2 Plan',
                sort: true,
                searchable: true
            },
            {
                dataField: 'username',
                text: 'Username',
                sort: true,
                searchable: true
            },
            {
                dataField: 'pulseduration',
                text: 'Pulse Duration',
                sort: false,
                searchable: false
            },
            {
                dataField: 'pulse_rate',
                text: 'Pulse Price',
                sort: false,
                searchable: false
            },
            {
                dataField: 'campaign_name',
                text: 'Campaign Name',
                sort: true,
                searchable: true
            },
            {
                dataField: 'campaign_start_date',
                text: 'Campaign Start Date',
                sort: false,
                searchable: false
            },
            {
                dataField: 'campaign_end_date',
                text: 'Campaign End Date',
                sort: false,
                searchable: false
            },
            {
                dataField: 'campaign_status',
                text: 'Campaign Status',
                sort: true,
                searchable: true
            },
            {
                dataField: 'calls_dialed',
                text: 'Calls Dialed',
                sort: false,
                searchable: false
            },
            {
                dataField: 'connected_calls',
                text: 'Connected Calls',
                sort: false,
                searchable: false
            },
            {
                dataField: 'reseller1_pulse',
                text: 'Reseller2 Pulse',
                sort: false,
                searchable: false
            },
            {
                dataField: 'user_pulse',
                text: 'User Pulse',
                sort: false,
                searchable: false
            },
            {
                dataField: 'sms_count',
                text: 'User SMS Count',
                sort: false,
                searchable: false
            }
        ];
        }

        let summary = undefined;
        if(this.state.userParentType === "reseller"){
         summary = Array.isArray(summaryList) ? summaryList.map((user, index) => {
            let status = '';
             
            console.log("cam : "+user.campaignStatus);
            const cs = Number(user.campaignStatus);
			switch (cs) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			return {
					reseller2_username: user.reseller2Username,
					reseller2_plan: user.reseller2PlanName,
                    reseller1_username: user.reseller1Username,
					reseller1_plan: user.reseller1PlanName,
					username: user.username,
					pulseduration: user.userPulseDuration,
					pulse_rate: user.userPulsePrice,
					campaign_name: user.campaignName,
					campaign_start_date: user.scheduleDate,
					campaign_end_date: user.endDate,
					campaign_status: status,
					calls_dialed: user.callsDailed,
                    connected_calls: user.callsConnected,
                    reseller2_pulse : user.reseller2Pulses,
                    reseller1_pulse : user.reseller1Pulses,
                    user_pulse: user.userPulses,
                    sms_count : user.smsCount
				};
			}
         ) : []
        }else{

            summary = Array.isArray(summaryList) ? summaryList.map((user, index) => {
            let status = '';
             
            console.log("cam : "+user.campaignStatus);
            const cs = Number(user.campaignStatus);
			switch (cs) {
				case 0:
					status = 'Ready';
					break;
				case 21:
					status = 'Processing';
					break;
				case 1:
					status = 'Running';
					break;
				case 2:
					status = 'Complete';
					break;
				case 3:
					status = 'Low Balance';
					break;
				case 4:
					status = 'Error';
					break;
				case 5:
					status = 'Error';
					break;
				case 6:
					status = 'Cancelling';
					break;
				case 7:
					status = 'Cancelled';
					break;
				case 8:
					status = 'Pausing';
					break;
				case 9:
					status = 'Paused';
					break;
				case 10:
					status = 'Resuming';
					break;
				case 22:
					status = 'Waiting For Channels';
						break;
				default:
					status = '';
					break;
			}
			return {
					reseller1_username: user.reseller1Username,
					reseller1_plan: user.reseller1PlanName,
                    reseller1_pulse: user.reseller1Pulses,
					reseller2_username: user.reseller2Username,
					reseller2_plan: user.reseller2PlanName,
					username: user.username,
					pulseduration: user.userPulseDuration,
					pulse_rate: user.userPulsePrice,
					campaign_name: user.campaignName,
					campaign_start_date: user.scheduleDate,
					campaign_end_date: user.endDate,
					campaign_status: status,
					calls_dialed: user.callsDailed,
                    connected_calls: user.callsConnected,
                    reseller2_pulse : user.reseller2Pulses,
                    user_pulse: user.userPulses,
                    sms_count : user.smsCount
				};
			}
         ) : []

        }

        const { SearchBar, ClearSearchButton } = Search;

        const resellerUsersList = cloneDeep(this.state.resellerUserList);
		let usersDropdown = '';
		if (!isEmpty(resellerUsersList) && (!('error' in resellerUsersList))) {
			usersDropdown = Array.isArray(resellerUsersList) ? resellerUsersList.map((obj, index) => {
				return <option value={obj.username} key={`planid${index}`} >{obj.username}</option>
			}) : []

		} else {
			usersDropdown = <option value="" >No User found</option>
		}

        return( <Card>

            <Card.Header>Reseller Summary List</Card.Header>

            <Card.Body>
					<div className="user-list-table-cont">
                        
                        <Form className="add-campaign-form" method='post' onSubmit={(e) => this.searchCdr(e)}>
                            <Row>
                                {/* <Col sm={12}>
                                    <Row> */}
                                        <Col sm={3}>
                                            {/* <Form.Group className="form-group col-md-6 float-left">
                                                <Form.Control name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} />
                                            </Form.Group> */}

                                            <Form.Group className="form-group  control-label">
											  <Form.Select className="dd"  name="username" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select User</option>
												{usersDropdown}
											  </Form.Select>
										    </Form.Group>
                                        </Col>
                                        
                                        <Col sm={3}>
                                            <Form.Group className="form-group col-md-6 float-left">
					                        <DatePicker
						                             className="form-control"
						                             dateFormat="yyyy-MM-dd"
                                                     selected={this.state.startDate}
                                                     onChange={this.handleStartDateChange}
                                                    placeholderText="Start Date"
					                       />
				                           </Form.Group>
                                        </Col>
                                        <Col sm={3}>
                                           <Form.Group className="form-group col-md-6 float-left">
					                        <DatePicker
						                             className="form-control"
						                             dateFormat="yyyy-MM-dd"
						                             selected={this.state.endDate}
                                                     onChange={this.handleEndDateChange}
                                                     placeholderText="End Date"
					                       />
				                           </Form.Group>
                                        </Col>
                                        <Col sm={3}>
                                            <Button variant="primary" type="submit">Search</Button>
                                        </Col>
                                    </Row>
                                {/* </Col>
                            </Row> */}
                        </Form>
                        <br/>



                        {<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="userId"
										data={summary}
										columns={columns}
										search
										bootstrap4
									>
										{
											props => (
												<div>
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
                                                    <hr />
													<BootstrapTable defaultSorted={defaultSorted} noDataIndication={() => (<div>No data available</div>)} hover bordered={true} wrapperClasses="table-responsive" classes="userlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
                    </div>
            </Card.Body>

        </Card>)

    }

}