import React, { Component } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { cloneDeep, isEmpty } from 'lodash';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Redirect } from "react-router-dom";
import "../index.css";

// import "./index.css";
class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			username: '',
			emailid: '',
			number: '',
			company: '',
			planName: '',
			userType: '',
			credits: '',
			planList: []
		};

	}
	componentDidMount() {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					name: res.name,
					username: res.username,
					emailid: res.emailid,
					number: res.number,
					company: res.company,
					planName: res.planName,
					userType: res.userType,
					credits: res.credits
				})
			})
			.catch(err => { console.log('Error in fetching Users data', err) });
	}


	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}
	hideModal = () => {
		this.setState({
			redirectToUserManagement: this.state.redirectToUserManagement
		});
	}

	render() {

		return (

			<Form>
				<Row>
					<Col sm={12}>
						<Card>
							<Card.Header>User Profile</Card.Header>
							<Card.Body>
								<Row>
									<Col sm={4}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Username</Form.Label>
											<Form.Control disabled required name="username" type="text" placeholder="Username" onChange={e => this.setvalue(e)} value={this.state.username} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group controlId="referrerDomain">
											<Form.Label>Name</Form.Label>
											<Form.Control disabled required name="name" type="text" placeholder="Name" onChange={e => this.setvalue(e)} value={this.state.name} />
										</Form.Group>
									</Col>
								</Row>
								
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Email ID</Form.Label>
											<Form.Control disabled required name="emailid" type="email" placeholder="Email ID" onChange={e => this.setvalue(e)} value={this.state.emailid} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Mobile Number</Form.Label>
											<Form.Control disabled required name="number" type="number" placeholder="Mobile Number" onChange={e => this.setvalue(e)} value={this.state.number} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Company</Form.Label>
											<Form.Control disabled required name="company" type="text" placeholder="Company" onChange={e => this.setvalue(e)} value={this.state.company} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>User Type</Form.Label>
											<Form.Control disabled required name="userType" type="text" placeholder="UserType" onChange={e => this.setvalue(e)} value={this.state.userType} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Plan Name</Form.Label>
											<Form.Control disabled required name="planName" type="text" placeholder="Plan Name" onChange={e => this.setvalue(e)} value={this.state.planName} />
										</Form.Group>
									</Col>
									<Col sm={4}>
										<Form.Group>
											<Form.Label>Balance(Rs)</Form.Label>
											<Form.Control disabled required name="credits" type="text" placeholder="Balance" onChange={e => this.setvalue(e)} value={this.state.credits} />
										</Form.Group>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Form>
		);
	}

}
export default UserProfile;