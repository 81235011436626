import React ,{ useState, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import { Fetcher } from "../../Helpers/fetcher";
import './index.css';
const SideNav = (props) => {
	let urlPrefix;
	if (props.app.mode === 'dev') {
		urlPrefix = '/build';
	} else {
		urlPrefix = '';
	}
	
	const [moduleId, setModuleId] = useState();
	const [apiModuleId, setApiModuleId] = useState();
	const [ivrId, setIvrId] = useState();
	useEffect(() => {
    const apiPath = props.app.apiPath;
    const userId = props.app.userId;

    Fetcher.get(apiPath + "/api/obd/user/profile/" + userId)
      .then((res) => {
        console.log(res);
        let modules = res.modules;
        if (modules.length > 0) {
          for (let i = 0; i <= modules.length; i++) {
            if (modules[i].moduleId === 3) {
              setModuleId(3);
            }
			if(modules[i].moduleId === 4){
				setApiModuleId(4);
			}
			if(modules[i].moduleId === 1){
				setIvrId(1);
			}
          }
        }
      })
      .catch((err) => {
        console.log("Error in fetching Users data", err);
      });
     });

	return (
		<div className="sidebar">
			<div className="scrollbar-container sidebar-nav">
				{props.app.role === 'ROLE_USER' && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					{(ivrId === 1) && <> <li className="nav-title">Custom IVR</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-custom-ivr`}><i className="fa fa-plus-circle"></i> Add Custom IVR</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/ivr-list`}><i className="fa fa-plus-circle"></i> IVR List</Link></li>
					</>}
					<li className="nav-title">Voice Campaigns</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-campaign`}><i className="fa fa-plus-circle"></i> Compose Voice Campaign</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-campaign-summary`}><i className="fa fa-table"></i> Live Campaign Summary</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/historical-voice-campaign-summary`}><i className="fa fa-table"></i> Historical Campaign Summary</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/schedule-voice-campaign-summary`}><i className="fa fa-table"></i> Schedule Campaign Summary</Link></li>
					<li className="nav-title">Reports</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="fa fa-table"></i> Download Report</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/call-detail-records`}><i className="mr-2 fa fa-phone"></i>Call Detail Records</Link></li>
					<li className="nav-title">Voice Blacklist</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-blacklist`}><i className="fa fa-plus-circle"></i> Add to Blacklist</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/bulk-voice-blacklist`}><i className="fa fa-plus-circle"></i> Add Bulk Blacklist</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-blacklist`}><i className="fa fa-table"></i> View Blacklist</Link></li>
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-files`}><i className="fa fa-plus-circle"></i> Add Voice File</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> View Voice Files</Link></li>
				    
					{(apiModuleId === 4) && <>
					<li className="nav-title">Api Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-api-key`}><i className="fa fa-plus-circle"></i> Create Api Key </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-api-key-list`}><i className="fa fa-table"></i> Api Key List </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-summary-list`}><i className="fa fa-table"></i> Api Summary </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-historical-summary-list`}><i className="fa fa-table"></i> Api Historical Summary </Link></li>
					</>}

					{(moduleId === 3) && <> <li className="nav-title">Web Hook Management</li>
                    <li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-webhook`}><i className="fa fa-plus-circle"></i> Add Webhook</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/webhook-list`}><i className="fa fa-table"></i> View Webhook</Link></li>
					 </>}
				</ul>}

				{(props.app.role === 'ROLE_ADMIN') && <ul className="nav">
					<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/reseller-summary`}><i className="mr-2 fa fa-list"></i>Reseller Summary</Link></li>
					<li className="nav-title">Api Key Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-summary-list`}><i className="fa fa-table"></i> Api Summary </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-historical-summary-list`}><i className="fa fa-table"></i> Api Historical Summary </Link></li>
					<li className="nav-title">Voice Plans</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="fa fa-plus-circle"></i> Add Voice Plan</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="fa fa-table"></i> View Voice Plans</Link></li>
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> View Voice Files</Link></li>
				</ul>}

				{(props.app.role === 'ROLE_SADMIN') && <ul className="nav">
				<li className="nav-item"><Link className="nav-link active" to="/" aria-current="page"><i className="fa fa-dashboard"></i> Dashboard</Link></li>
					<li className="nav-title">User Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-user`}><i className="fa fa-plus-circle"></i> Create User </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/user-list`}><i className="fa fa-table"></i> User List</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/credits-history`}><i className="fa fa-table"></i> Credits History</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/download-report`}><i className="fa fa-table"></i> Download Report</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/call-detail-records`}><i className="mr-2 fa fa-phone"></i>Call Detail Records</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/reseller-summary`}><i className="mr-2 fa fa-list"></i>Reseller Summary</Link></li>
					<li className="nav-title">Campaign Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-superadmin-campaign-summary`}><i className="fa fa-table"></i> Voice Campaign Summary</Link></li>
					<li className="nav-title">CLI Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli`}><i className="fa fa-plus-circle"></i> Upload CLI</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/allocate-cli`}><i className="fa fa-plus-circle"></i> Allocate CLI </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-cli`}><i className="fa fa-table"></i> Archived CLI List </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-list`}><i className="fa fa-table"></i> CLI List</Link></li>
					<li className="nav-title">Api CLI Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/upload-cli-api`}><i className="fa fa-plus-circle"></i> Upload Api CLI</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/archived-api-cli`}><i className="fa fa-table"></i> Archived Api CLI List </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/cli-api-list`}><i className="fa fa-table"></i> Api CLI List</Link></li>
					<li className="nav-title">Api Key Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-summary-list`}><i className="fa fa-table"></i> Api Summary </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/api-key-historical-summary-list`}><i className="fa fa-table"></i> Api Historical Summary </Link></li>
					<li className="nav-title">Dialer Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-dialer`}><i className="fa fa-plus-circle"></i> Create Dialer </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/dialer-list`}><i className="fa fa-table"></i> Dialer List</Link></li>
					<li className="nav-title">Trunk Management</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/create-trunk`}><i className="fa fa-plus-circle"></i> Create Trunk </Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/trunk-list`}><i className="fa fa-table"></i> Trunk List</Link></li>
					<li className="nav-title">Voice Plans</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/add-voice-plan`}><i className="fa fa-plus-circle"></i> Add Voice Plan</Link></li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-plans`}><i className="fa fa-table"></i> View Voice Plans</Link></li>
					<li className="nav-title">Voice Library</li>
					<li className="nav-item"><Link className="nav-link" to={`${urlPrefix}/voice-files`}><i className="fa fa-table"></i> Prompts for Approval</Link></li>
				</ul>}

			</div>
		</div>
	);

}

export default SideNav;
