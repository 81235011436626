import React, { Component } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../../Helpers/fetcher.js";
import { Notify } from "../../../Helpers/notification.js";
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import "./index.css";
class ArchivedApiCli extends Component {
	constructor(props) {

		super(props);
		this.state = {
			cliList: [],
			confirmArchive: false,
			selectedCliId: 0,
			showAddCreditModal: false
		}
	}
	componentDidMount() {
		this.getCli();
	}


	getCli = () => {
		const apiPath = this.props.app.apiPath;

		Fetcher.get(apiPath + '/api/obd/cli/archive/api')
			.then(res => {
				this.setState({
					cliList: res,
					showAddCreditModal: false,
					confirmArchive: false,
				})
			})
			.catch(err => { console.log('Error in fetching CLI List', err) });
	}

	confirmArchive = (status, cliId) => {
		this.setState({
			confirmArchive: status,
			selectedCliId: cliId
		});
	}

	unarchiveCli = () => {
		const cliId = this.state.selectedCliId;
		let allocated = 1;
		
		const data = {
			cliId: cliId,
			allocated: allocated
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/cli/archive', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getCli();
			})
			.catch(err => {
				console.log('Error in Unarchiving CLI', err);
				Notify.notify('Error in Unarchiving CLI');
			});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAddCreditModal: false,
		});
	}

	showAddCreditModal = (userId) => {
		this.setState({
			showAddCreditModal: true,
			selectedCliId: userId
		});
	}


	render() {

		const cliList = this.state.cliList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'cliId',
					text: 'CLI Id',
					sort: false,
					hidden: true
				},
				{
					dataField: 'cli',
					text: 'CLI',
					sort: true,
					searchable: true
				},
				{
					dataField: 'pilotNumber',
					text: 'Pilot Number',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		}
		else {
			columns = [
				{
					dataField: 'cliId',
					text: 'CLI Id',
					sort: false,
					hidden: true
				},
				{
					dataField: 'cli',
					text: 'CLI',
					sort: true,
					searchable: true
				},
				{
					dataField: 'pilotNumber',
					text: 'Pilot Number',
					sort: true,
					searchable: true
				},
				{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false
				},];
		}
		const clis = Array.isArray(cliList) ? cliList.map((cli, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				//let action = [ <Link data-tip="Edit CLI" key={`${index}-edit-cli`} className="edit-cli" to={`${this.props.app.urlPrefix}/update-cli/${cli.cliId}`}><i className="fa fa-edit"></i></Link> ];
				let action = [<a data-tip="Delete CLI" key={`${index}-archive-cli`} className="btn btn-sm btn-success" onClick={(e) => { this.confirmArchive(true, cli.cliId) }} href="#">Unarchive CLI</a>];
				//action.push( <a data-tip="Delete CLI" key={`${index}-delete-cli`} className="delete-cli" onClick={( e ) => { this.confirmArchive( true, cli.cliId ) }} href="#"><i className="fa fa-trash"></i></a> )
				return {
					cliId: cli.cliId,
					cli: cli.cli,
					pilotNumber: cli.pilotNumber,
					action: action
				};
			}
			else {
				let action = [<a className="btn btn-sm btn-success" key={`${index}-archive-cli`}  onClick={(e) => { this.confirmArchive(true, cli.cliId) }} href="#">Unarchive CLI</a>];
				//let action = [ <Link data-tip="Edit CLI" key={`${index}-edit-cli`} className="edit-cli" to={`${this.props.app.urlPrefix}/update-cli/${cli.cliId}`}><i className="fa fa-edit"></i></Link> ];
				//action.push( <a data-tip="Delete CLI" key={`${index}-delete-cli`} className="delete-cli" onClick={( e ) => { this.confirmArchive( true, cli.cliId ) }} href="#"><i className="fa fa-trash"></i></a> )
				return {
					cliId: cli.cliId,
					cli: cli.cli,
					pilotNumber: cli.pilotNumber,
					action: action
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'cliId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: clis.length,
			sizePerPage: 10,
			sizePerPageList: [10, 25, 50, 100]
		};

		return (
			<Card>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}
				<Card.Header>Archived CLI List</Card.Header>
				<Card.Body>
					<div className="widget-list-table-cont">
						{/* <h3>Campaign Summary</h3> */}
						{/*{!isEmpty( this.state.cliList ) && <PaginationProvider pagination={paginationFactory( paginationOption )}>*/}
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="cliId"
										data={clis}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'archived_cli.csv'
										  } }

									>
										{
											props => (
												<div className="">
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													{/*<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/add-cli`}>Allocate CLI</Link>*/}
													<hr />
													<BootstrapTable defaultSorted={defaultSorted} bordered={true} hover condensed wrapperClasses="table-responsive" classes="widgetlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
													<ReactTooltip />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>

					<Modal size="md" show={this.state.confirmArchive} onHide={() => this.confirmArchive(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Unarchive CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to unarchive this CLI?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmArchive(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.unarchiveCli} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default ArchivedApiCli;